import { Route, Routes } from "react-router-dom";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero/Hero";
// import About from "./Components/About/About";
// import Schedule from "./Components/Schedule/Schedule";
import  ContactForm  from "./Pages/ContactForm";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <div>
        <Header />
        <Hero />
        {/* <About /> */}
        {/* <Schedule /> */}
        <Routes>
          <Route path="/ContactForm" element={<ContactForm />} />
        </Routes>
        <Footer />
      
    </div>
  );
}

export default App;
