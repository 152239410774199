import React from "react";

const ContactForm = () => {
  return (
    <div className="max-w-[1640px] mx-auto p-2">
      <h1>Contact Us Today!</h1>
    </div>
  );
};

export default ContactForm;
