import React from "react";
import { Link } from "react-router-dom";
import WareHouse from "../../Assets/warehouseHero.jpg";

function Hero() {
  return (
    <div className="max-w-[1640px] mx-auto p-4">
      <div className="max-h-[500px] relative">
        {/* Overlay */}
        <div
          className="absolute w-full h-full text-gray-200 max-h-[500px] bg-black/40
        flex flex-col justify-center text-center"
        >
          <h1 className="px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold">
            In-Tech
          </h1>
          <h1 className="px-4 text-2xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-gray-400">
            logistics, workflow, business processes, agriculture, inventory done
            right.
          </h1>
        </div>
        <img
          className="w-full max-h-[500px] object-cover"
          src={WareHouse}
          alt=""
        />
      </div>
      <div className=" grid grid-flow-col grid-cols-2 font-medium mt-6 bg-slate-700/60 h-42 mx-auto justify-center px-12 py-6 text-2xl">
        <p className="md:mt-6 lg:mx-6">
          At In-Tech we use our expertise in logistics, workflow optimization,
          business processes, agriculture, inventory, and development to help
          you diagnose and define your business needs.
        </p>
        <div className="flex justify-end md:justify-between md:mx-24 lg:mx-[200px] mt-4">
        <Link 
        target="blank"
        to="https://calendly.com/megawarehouse/mwappointment?month=2023-05">
          <button className="rounded-full border-2 border-black px-6 py-8 my-4 text=xl font-medium">
            Schedule Today!
          </button>
        </Link>
          
        </div>
      </div>
    </div>
  );
}

export default Hero;
