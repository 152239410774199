import React from "react";
import { Link } from "react-router-dom";
import { IoLogoFacebook, IoLogoTwitter } from "react-icons/io";
import Logo from "../../Assets/inTechLogo.jpg";

// TODO:Refactor footer for responsive interface

const Footer = () => {
  return (
    <div className="max-w-[1640px] mx-auto flex justify-between items-center p-4">
      <div className="mt-2">
        <Link to="/">
          <img src={Logo} alt="/" />
        </Link>
        <div className="flex px-4 justify-center text-center md:justify-center">
          <IoLogoFacebook size={30} className="cursor-pointer" />
          <IoLogoTwitter size={30} className="cursor-pointer" />
        </div>
        <div className="flex flex-col p-4 mx-auto my-6 text-left text-2xl font-medium">
          <p>72193 CR 133</p>
          <p>Syracuse, IN</p>
          <p>46567</p>
          <p>574-457-0186</p>
        </div>
      </div>
      {/* Contact form */}
      <div></div>
    </div>
  );
};

export default Footer;
