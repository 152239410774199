import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/inTechLogo.jpg";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Header = () => {
  const [nav, setNav] = useState(false);

  return (
    <div className="max-w-[1640px] mx-auto flex justify-between items-center p-4">
      {/* Left side */}
      {/* This bottom div holds the app name as well as a toggle button */}
      <div className="flex items-center">
        <div onClick={() => setNav(!nav)} className="cursor-pointer">
          <AiOutlineMenu size={30} />
        </div>
        <Link to="/">
          <img src={Logo} alt="/" />
        </Link>
      </div>
      {/* Mobile Menu */}
      {/* overlay */}

      {nav ? (
        <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0"></div>
      ) : (
        ""
      )}
      {/* sidedrawer menu */}
      <div
        className={
          nav
            ? "fixed top-0 left-0 w-[300px] h-screen bg-white z-10 duration-300"
            : "fixed top-0 left-[-100%] w-[300px] h-screen bg-white z-10 duration-300"
        }
      >
        <AiOutlineClose
          onClick={() => setNav(!nav)}
          size={30}
          className="absolute right-4 top-4
        cursor-pointer"
        />
        <Link to="/" onClick={() => setNav(!nav)}>
          <img src={Logo} alt="/" />
        </Link>
        <nav>
          <ul className="flex flex-col p-4 text-gray-800 font-medium">
            <Link to="/about">
              <li className="text-xl py-4 flex cursor-pointer hover:bg-black hover:text-white">
                About Us
              </li>
            </Link>
            <Link 
        target="blank"
        to="https://calendly.com/megawarehouse/mwappointment?month=2023-05">
              <li className="text-xl py-4 flex cursor-pointer hover:bg-black hover:text-white">
                Warehousing
              </li>
            </Link>
            <Link to="/tech">
              <li className="text-xl py-4 flex cursor-pointer hover:bg-black hover:text-white">
                Tech Solutions
              </li>
            </Link>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
